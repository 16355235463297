<template>
  <v-row class="flex-wrap justify-center fixed align-center">
    <Overlay :loading="isLoading" />
    <!-- <simple-alert :open="open" title="Apenas gestores podem acessar essa página" /> -->
     <v-col cols="6" class="pa-0" >
      <v-row class="justify-center" >
        <div class="column align-self-center" >
          <v-row class="mb-10">
            <v-img
              class="mx-auto"
              width="80"
              src="../../assets/logoMis.png"/>
          </v-row>
          <v-row class="column">
            <!-- Admin -->
            <div>
              <div class="subtitle-2 font-weight-regular gray--text">
                <v-btn
                  text
                  color="gray"
                  class="pa-0"
                  @click="
                    $router.push({
                      path: '/',
                    })
                  "
                >
                  <v-icon>mdi-arrow-left</v-icon> Voltar
                </v-btn>
              </div>
              <div class="text-h5 font-weight-bold mt-3">Login</div>
              <div class="button mt-2 logo">Login para Gestor</div>
              <div class="mt-5 containerInput">
                <v-text-field
                  label="CPF"
                  placeholder="Insira seu CPF"
                  outlined
                  color="#1c3b7b"
                  flat
                  v-mask="'###.###.###-##'"
                  v-model="cpf"
                  @keypress.enter="authenticate"
                ></v-text-field>
                <v-text-field
                  label="Senha"
                  placeholder="Insira sua senha"
                  outlined
                  color="#1c3b7b"
                  flat
                  type="password"
                  v-model="password"
                  @keypress.enter="authenticate"
                ></v-text-field>
                <v-btn
                  depressed
                  width="100%"
                  color="#1C3B7B"
                  class="pa-6 white--text"
                  @click="authenticate"
                >
                  Entrar
                </v-btn>
              </div>
              <div class="mt-10 text-center">
                <v-btn
                  class="text-decoration-underline pa-0 ma-0"
                  color="#686868"
                  style="font-size: 0.68rem"
                  text
                >
                  Política de Privacidade e Termos de Serviço
                </v-btn>
              </div>
            </div>
          </v-row>
        </div>
      </v-row>
    </v-col>
    <v-col cols="6" class="pa-0" v-if="!$vuetify.breakpoint.mobile">
      <v-img
        class="wallpaper"
        :lazy-src="require('../../assets/login.png')"
        :src="require('../../assets/login.png')"
      ></v-img>
    </v-col>
  </v-row>
</template>

<script>
// import SimpleAlert from "../../components/Dialogs/SimpleAlert.vue"
import { mapActions, mapGetters } from "vuex";
import Overlay from "../../components/Overlay/Overlay.vue";

export default {
  components: {
    Overlay,
    // SimpleAlert
  },
  data: () => ({
    open: false,
    isLoading: false,
    cpf:"",
    password:"",
  }),
  methods: {
    ...mapActions("authentication", ["validateSession", "login"]),
    async authenticate() {
      try {
        this.isLoading = true;
        await this.login({
          cpf: this.cpf,
          password: this.password,
        });
        this.open = true;
        this.$router.push({ name: "ManagerDashboard" });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "authentication/hasPermission",
    }),
  },
};
</script>

<style>
.logo {
  color: #1c3b7b;
}
.containerInput {
  width: 310px;
}
.fixed {
  position: fixed;
  width: 100vw;
  height: 100vh;
}
.wallpaper {
  background-image: url("../../assets/login.png");
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  flex: 1;
}
</style>
